export default {
  baseURL: "https://gobartsdev.com/api",
};

export const imageBase ="https://gobartsdev.com/storage/images";

export const categoriesFromConfig = [
  "Camp Furniture",
  "Bedding Amenities",
  "Office Furniture",
  "School Furniture",
  "Home Appliances"
];

export const subCategoriesFromConfig = {
  "Camp Furniture": [
    "Bunk beds",
    "Single Beds",
    "Steel lockers",
    "⁠Shoe racks",
    "⁠Dining table and chairs"
  ],
  "Bedding Amenities": [],
  "Office Furniture": [
    "Office Chairs",
    "Office Desks",
    "⁠Work Stations",
    "⁠Cabinets",
    "⁠Meeting Tables",
    "Reception Tables"
  ],
  "School Furniture": [],
  "Home Appliances": []

}


export const productPageDescription = {
  "Camp Furniture": "Durable and versatile labor camp furniture, including steel bunk beds, steel single beds, steel locker cabinets, and steel shoeracks, designed for temporary accommodations and staff quarters.",

  "Bedding Amenities": "High-quality bedding essentials, including medicated and medical mattresses, providing comfort and support for staff accommodation and residential use.",

  "Office Furniture": "Ergonomic and functional office solutions, such as desks, chairs, steel cupboards, file cabinets, and secure storage lockers, tailored for modern workplaces.",

  "School Furniture": "Sturdy and student-friendly furniture, including training chairs and tables, designed to enhance learning environments with durable and versatile designs.",

  "Home Appliances": "Reliable and efficient home essentials, such as water coolers, cast iron stoves, coin-operated washing machines, and pantry furniture like pantry tables and chairs, perfect for everyday needs in staff accommodations and residential settings."
}